import { TOGGLE_CONFIRM_DIALOG } from "../actions";

const initialState = {
  isShow: false,
  onSuccessFunc: () => {},
  title: "",
  message: "",
};

export default function confirmDialogReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_CONFIRM_DIALOG:
      return {
        ...state,
        isShow: action.payload.isShow,
        onSuccessFunc: action.payload.onSuccessFunc,
        title: action.payload.title,
        message: action.payload.message,
      };
    default:
      return state;
  }
}
