import { TOGGLE_SIDEBAR } from "../actions";

const initialState = {
  isOpened: false,
};

export default function sidebarReducer (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {...state, isOpened: action.payload};
    default:
      return state;
  }
}
