import React, { lazy } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Redirect } from "react-router";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import InfoPopup from "./components/InfoPopup";
import { OpenRoute, PrivateRoute } from "./Routes";
import "./App.scss";
import { useSelector } from "react-redux";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";

const LoginLayout = lazy(() => import("./layouts/LoginLayout"));
const MainLayout = lazy(() => import("./layouts/MainLayout"));

const theme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      paper: "#282c34",
    },

    default: {
      main: "#b411d0",
    },
    primary: {
      main: "#2196f3",
    },
    secondary: {
      main: "#e61610",
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      root: { borderRadius: 5 },
    },
    MuiFormControl: {
      root: {
        "& MuiInput-input": {
          color: "#babac0",
        },
      },
    },
  },
});

const App = () => {
  const isAuth = useSelector(({ login }) => login.isAuth);

  moment.locale("ru");

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <InfoPopup />
          <Switch>
            <OpenRoute exact path="/" component={LoginLayout} />
            <PrivateRoute
              path={["/leads", "/employees", "/clients", "/dealers", "/finances", "/requests", "/financial_assets"]}
              component={MainLayout}
              auth={isAuth}
            />
            <Redirect from="*" to="/" />
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default App;
