export const TOGGLE_CONFIRM_DIALOG = "TOGGLE_CONFIRM_DIALOG";

export function toggleConfirmDialog(isShow = false, onSuccessFunc = () => {}, title = "", message = "") {
  return {
    type: TOGGLE_CONFIRM_DIALOG,
    payload: {
      isShow: isShow,
      onSuccessFunc: onSuccessFunc,
      title: title,
      message: message,
    },
  };
}
