import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug:false,
    resources:{
      en:{translation:en},
    },
    load:"unspecific",
    whitelist:["en"],
    fallbackLng:["en"],
    nsSeparator:"::",
    keySeparator:false, // we do not use keys in form messages.welcome
    interpolation:{
      escapeValue:false, // react already safes from xss
    },
  });

export default i18n;
