export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export const toggleSidebar = isOpened => dispatch => {
  return dispatch(toggleSidebar1(isOpened));
};

function toggleSidebar1 (isOpened) {
  return {
    type: TOGGLE_SIDEBAR,
    payload: isOpened,
  };
}

