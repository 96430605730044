import { combineReducers } from "redux";

import loginReducer from "./login";
import sidebarReducer from "./sidebar";
import infoPopupReducer from "./infoPopup";
import confirmDialogReducer from "./confirmDialog";

export default combineReducers({
  login: loginReducer,
  sidebar: sidebarReducer,
  infoPopup: infoPopupReducer,
  confirmDialog: confirmDialogReducer,
});
