import React from "react";
import { IconButton, Snackbar } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { setInfoPopup } from "../../actions";

const InfoPopup = () => {
  const dispatch = useDispatch();

  const popupMessage = useSelector(({ infoPopup }) => infoPopup.message);
  function closeInfoPopup() {
    dispatch(setInfoPopup(""));
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={!!popupMessage}
      autoHideDuration={4000}
      onClose={closeInfoPopup}
      message={popupMessage}
      action={
        <IconButton size="small" color="inherit" onClick={closeInfoPopup}>
          <Close fontSize="small" />
        </IconButton>
      }
    />
  );
};

export default InfoPopup;
